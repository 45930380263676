import React, { useState, useEffect, useCallback } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAlertwestTheme } from "@digitalpath-inc/alertwest-components-react/theme";
import {
  CssBaseline,
  Container,
  ThemeProvider,
  Box,
  Typography,
  Paper,
  useTheme,
} from "@mui/material";
import { SignInFlowStates } from "./constants/SignInFlowStates";
import { AuthProvider } from "./contexts/AuthContext";
import AuthState from "./AuthState";

import Loading from "./components/misc/Loading";
import ErrorPage from "./components/ErrorPage";
import AdminForgotPasswordForm from "./components/auth/AdminForgotPasswordForm";
import LoginFlow from "./components/auth/LoginFlow";

const theme = GetAlertwestTheme({});

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: (
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "8px", // Thin scrollbar
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent", // Transparent track
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent white for dark mode
                borderRadius: "4px", // Rounded edges
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.3)", // Slightly brighter on hover
              },
              scrollbarWidth: "thin", // Firefox support
              scrollbarColor: "rgba(255, 255, 255, 0.2) transparent", // Firefox scrollbar colors
            }}
          >
            <Outlet />
          </Container>
        ),
        children: [
          { index: true, element: <LoginFlow /> },
          { path: "recovery", element: <AdminForgotPasswordForm /> },
        ],
      },
    ],
  },
]);

function App() {
  const [configLoading, setConfigLoading] = useState(true);
  const [configError, setConfigError] = useState(null);

  // Initialize configuration
  const initConfig = useCallback(async () => {
    try {
      // Initialize auth state which includes config loading and user session check
      await AuthState.initialize();
      setConfigLoading(false);
    } catch (error) {
      console.error("Error initializing auth:", error);
      setConfigError(error.message);
      setConfigLoading(false);
    }
  }, []);

  useEffect(() => {
    initConfig();
  }, [initConfig]);

  const ErrorDisplay = () => {
    const theme = useTheme();
    return (
      <Box
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        bgcolor={theme.palette.background.default}
        pt={5}
      >
        <Box maxWidth={500} width="100%">
          <Paper
            sx={{
              p: 3,
              textAlign: "center",
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <Typography variant="h6" color="error" gutterBottom>
              Error loading configuration
            </Typography>
            <Typography variant="body1">{configError}</Typography>
          </Paper>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{ bgcolor: theme.palette.background.default, minHeight: "100vh" }}
      >
        {configError ? (
          <ErrorDisplay />
        ) : configLoading ? (
          <Loading message="Loading configuration..." />
        ) : (
          <AuthProvider authData={AuthState}>
            <RouterProvider router={router} />
          </AuthProvider>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          pauseOnFocusLoss
          closeOnClick
          draggable
          pauseOnHover
          theme="dark"
          transition={Slide}
        />
      </Box>
    </ThemeProvider>
  );
}

// Export functions that use the centralized AuthState for 3rd party microfrontends
export function getAuthData() {
  console.log("getAuthData called");
  return AuthState.getState();
}

export function getAuthSession() {
  console.log("getAuthSession called");
  return AuthState.getSession();
}

export function subscribeToAuthData(callback) {
  console.log("subscribeToAuthData called");
  return AuthState.subscribe(callback);
}

export function initializeAuthData() {
  console.log("initializeAuthData called");
  return AuthState.initialize();
}

export function signoutAuthSession() {
  console.log("signoutAuthSession called");
  return AuthState.signOut();
}

export default App;
