import React, { useState } from "react";
import { Box, Button, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { MailOutline, PhoneAndroid } from '@mui/icons-material';
import { MuiOtpInput } from "mui-one-time-password-input";

function VerificationForm({ type, onVerify, onResend, destination }) {
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState(false);

  const handleOtpChange = (newValue) => {
    setOtp(newValue.replace(/[^0-9]/g, ""));
  };

  const handleComplete = async (code) => {
    setIsVerifying(true);
    try {
      await onVerify(code);
    } catch (error) {
      console.error(`${type} verification failed:`, error);
      setError(true);
      setOtp(""); // Clear OTP for retry
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResend = async () => {
    try {
      await onResend();
    } catch (error) {
      console.error(`Failed to resend ${type} code:`, error);
      setError(true);
    }
  };

  const validateChar = (value) => {
    const isNumber = typeof value === "number";
    return (isNumber || value !== "") && !isNaN(Number(value));
  };

  return (
    <Box maxWidth={400} pt={5}>
      <Paper>
        <Typography variant="h4" px={2} py={1}>
          {type === "email" ? "Email Verification" : "Phone Verification"}
        </Typography>
        <Divider />
        <Box p={2}>
          <Box textAlign="center">
            {type === "email" ? (
              <MailOutline sx={{ height: "72px", width: "72px" }} />
            ) : (
              <PhoneAndroid sx={{ height: "72px", width: "72px" }} />
            )}
            <Box>
              <Typography gutterBottom>
                A code has been sent to <b>{destination}</b>.
              </Typography>
              <Typography gutterBottom>
                Enter it below to verify your {type === "email" ? "email address" : "phone number"}.
              </Typography>
            </Box>
            <MuiOtpInput
              autoFocus
              length={6}
              TextFieldsProps={{ 
                placeholder: '-', 
                error: error !== false, 
                size: "small",
                disabled: isVerifying
              }}
              value={otp}
              onChange={handleOtpChange}
              validateChar={validateChar}
              onComplete={handleComplete}
              disabled={isVerifying}
            />
          </Box>
          {error && (
            <Typography color="error" mt={1} textAlign="center">
              Invalid verification code. Please double-check and try again.
            </Typography>
          )}
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isVerifying && (
              <CircularProgress size={24} sx={{ mb: 2 }} />
            )}
            <Button
              onClick={handleResend}
              variant="text"
              fullWidth
            >
              Send a new code
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default VerificationForm;