// src/constants/AuthActions.js
export const AuthActions = {
  SET_LOADING: "SET_LOADING", // Sets the loading state
  SET_ERROR: "SET_ERROR", // Sets an error and transitions to ERROR state
  INITIALIZE: "INITIALIZE", // Initializes the auth state on mount
  SIGN_IN: "SIGN_IN", // Initiates sign-in process from INITIAL state
  RESET: "RESET", // Resets the flow to INITIAL state
  EMAIL_VERIFIED: "EMAIL_VERIFIED", // Confirms email verification
  PHONE_VERIFIED: "PHONE_VERIFIED", // Confirms phone verification
  MFA_SETUP_COMPLETE: "MFA_SETUP_COMPLETE", // Completes MFA setup
  MFA_SKIPPED: "MFA_SKIPPED", // Skips MFA setup
  MFA_CONFIRMED: "MFA_CONFIRMED", // Confirms MFA challenge during sign-in
  ACCOUNT_SETUP_CONFIRMED: "ACCOUNT_SETUP_CONFIRMED", // Confirms new password setup
  MFA_CHOICE: "MFA_CHOICE", // Selects MFA method
};
