import {
  getCurrentUser,
  fetchUserAttributes,
  fetchMFAPreference,
  signIn,
  signOut,
  fetchAuthSession,
  confirmSignIn,
  confirmUserAttribute,
  updateMFAPreference,
  setUpTOTP,
  verifyTOTPSetup,
} from "aws-amplify/auth";
import { Amplify } from "aws-amplify";

// Create a promise that will be resolved when the AuthState is initialized
let setInitializedResolver;
const initializePromise = new Promise((resolve) => {
  setInitializedResolver = resolve;
});

// Create the AuthState object
const AuthState = {
  state: {
    user: null,
    userAttributes: null,
    mfaSettings: null,
    isAuthenticated: false,
    isInitialized: false,
    signInResponse: null,
    loading: false,
    error: null,
  },
  config: null,
  listeners: new Set(),
  initializePromise,

  // This method is called whenever the state changes
  // It updates the state and notifies all listeners (including AuthContext)
  setState(newState) {
    // Log the state change for debugging
    console.log("AuthState.setState called with:", newState);

    // Create a new state object with the updates
    const updatedState = { ...this.state, ...newState };

    // Update the state
    this.state = updatedState;

    console.log("Broadcasting state change to listeners, new state:", {
      isAuthenticated: this.state.isAuthenticated,
      hasSignInResponse: !!this.state.signInResponse,
    });

    // Notify all listeners
    this.listeners.forEach((listener) => listener(this.state));
  },

  // Subscribe to state changes - used by AuthContext to stay in sync
  subscribe(listener) {
    this.listeners.add(listener);
    // Return unsubscribe function
    return () => this.listeners.delete(listener);
  },

  getState() {
    return this.state;
  },

  setInitialized() {
    setInitializedResolver();
  },

  async configureAmplify(config) {
    console.log(`Configuring Amplify with config: ${JSON.stringify(config)}`);
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.COGNITO_USER_POOL_ID,
          userPoolClientId: config.COGNITO_USER_POOL_CLIENT_ID,
          allowGuestAccess: true,
          loginWith: {
            oauth: {
              scopes: [
                "email",
                "openid",
                "phone",
                "profile",
                "aws.cognito.signin.user.admin",
              ],
              redirectSignIn: ["/login/mfa"],
              redirectSignOut: ["/"],
              responseType: "code",
            },
          },
        },
      },
    });
  },

  async getConfig() {
    if (!this.config) {
      const response = await fetch(`${process.env.COGNITO_CONFIG_DOMAIN}`);
      if (!response.ok) {
        throw new Error("Failed to fetch configuration");
      }
      this.config = await response.json();
      await this.configureAmplify(this.config);
    }
    return this.config;
  },

  async getUserData() {
    console.log("AuthState.getUserData called");
    try {
      const user = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const mfaSettings = await fetchMFAPreference();

      // Update the state with the user data
      this.setState({
        user,
        userAttributes,
        mfaSettings,
        isAuthenticated: true,
      });

      return { user, userAttributes, mfaSettings };
    } catch (error) {
      if (error.name === "UserUnAuthenticatedException") {
        this.setState({
          user: null,
          userAttributes: null,
          mfaSettings: null,
          isAuthenticated: false,
        });
        return null;
      }
      throw error;
    }
  },

  async initialize() {
    console.log("AuthState.initialize called");
    if (this.state.loading && !this.state.isInitialized) {
      // Wait until loading is complete before returning data
      return new Promise((resolve) => {
        console.log("Waiting for loading to complete");
        const checkLoading = () => {
          if (!this.state.loading && this.state.isInitialized) {
            console.log("Loading complete, returning true");
            resolve(true);
          } else {
            setTimeout(checkLoading, 100);
          }
        };
        checkLoading();
      });
    }
    try {
      this.setState({ loading: true });

      // Fetch configuration
      await this.getConfig();

      try {
        const user = await getCurrentUser();
        console.log("Fetching user attributes in initialize");

        // Both fetchUserAttributes and fetchMFAPreference make the same API call, but there's no way to access the raw data in the amplify sdk
        const [userAttributes, mfaSettings] = await Promise.all([
          fetchUserAttributes(),
          fetchMFAPreference(),
        ]);

        console.log("User data fetched successfully:", {
          user: user.username,
          attributes: userAttributes,
          mfa: mfaSettings,
        });

        this.setState({
          user,
          userAttributes,
          mfaSettings,
          isAuthenticated: true,
          loading: false,
          isInitialized: true,
        });
      } catch (error) {
        if (error.name === "UserUnAuthenticatedException") {
          console.log("User is not authenticated");
          this.setState({
            user: null,
            userAttributes: null,
            mfaSettings: null,
            isAuthenticated: false,
            loading: false,
            isInitialized: true,
          });
        } else if (
          error.name === "UserNotFoundException" ||
          error.message?.includes("User does not exist")
        ) {
          console.log(
            "User not found in Cognito, signing out and clearing session"
          );
          try {
            // Call signOut to clear the Cognito session
            await signOut();
          } catch (signOutError) {
            console.error(
              "Error signing out after user not found:",
              signOutError
            );
          }

          this.setState({
            user: null,
            userAttributes: null,
            mfaSettings: null,
            isAuthenticated: false,
            loading: false,
            isInitialized: true,
          });
        } else {
          console.error("Error initializing auth:", error);
          this.setState({
            error,
            loading: false,
            isInitialized: true,
          });
        }
      }
      return true;
    } catch (error) {
      console.error("Critical error initializing auth:", error);
      this.setState({
        error,
        loading: false,
        isInitialized: true,
      });
      throw error;
    }
  },

  async getSession() {
    if (!this.state.isAuthenticated) {
      return null;
    }
    try {
      return await fetchAuthSession();
    } catch (error) {
      console.error("Error fetching session:", error);
      if (
        error.name === "UserNotFoundException" ||
        error.message?.includes("User does not exist")
      ) {
        await this.signOut();
        return null;
      }
      throw error;
    }
  },

  async signOut() {
    console.log("AuthState.signOut called");
    try {
      await signOut();
      this.setState({
        user: null,
        userAttributes: null,
        mfaSettings: null,
        isAuthenticated: false,
        signInResponse: null,
      });
      return true;
    } catch (error) {
      console.error("Error in signOut:", error);
      this.setState({ error });
      return false;
    }
  },

  async handleSignIn(username, password) {
    try {
      this.setState({ loading: true });
      const signInResponse = await signIn({ username, password });
      console.log("Sign In Response:", JSON.stringify(signInResponse));

      if (signInResponse.isSignedIn) {
        console.log("User is signed in, fetching user data...");
        try {
          const userData = await this.getUserData();
          console.log("Sign in successful, userData:", userData);

          if (!userData) {
            throw new Error("Failed to get user data after sign-in");
          }

          // Update state with signInResponse and loading state
          this.setState({
            signInResponse,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching user data after sign-in:", error);
          this.setState({
            error,
            loading: false,
          });
        }
      } else {
        // For any other sign-in step, just store the response
        console.log("Sign-in step:", signInResponse.nextStep?.signInStep);
        this.setState({
          signInResponse,
          loading: false,
        });
      }

      return signInResponse;
    } catch (error) {
      if (error.name === "ResourceNotFoundException") {
        this.setState({
          signInResponse,
          loading: false,
        });
        return signInResponse;
      }
      console.error("Sign in error:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async handleAccountSetup(newPassword, userAttributes) {
    if (!this.state.signInResponse) {
      throw new Error("No signInResponse available for account setup");
    }

    try {
      this.setState({ loading: true });

      const response = await confirmSignIn({
        challengeResponse: newPassword,
        options: {
          userAttributes,
        },
      });
      console.log("Account setup response:", response);

      if (response.isSignedIn) {
        await this.getUserData();
        this.setState({
          signInResponse: null,
          loading: false,
        });
        return response;
      } else {
        this.setState({
          signInResponse: response,
          loading: false,
        });
        return response;
      }
    } catch (error) {
      console.error("Error confirming account setup:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async handleMFAChoice(mfaMethod) {
    try {
      const result = await confirmSignIn({
        challengeResponse: mfaMethod,
      });
      this.setState({
        signInResponse: result,
      });
      return result;
    } catch (error) {
      console.error("Error confirming MFA choice:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async handleMFAChallenge(confirmationCode) {
    if (!this.state.signInResponse) {
      console.error("No signInResponse available for MFA confirmation");
      this.setState({
        error: new Error("Missing signInResponse for MFA confirmation"),
      });
      throw new Error("Missing signInResponse for MFA confirmation");
    }

    try {
      this.setState({ loading: true });

      // Get the MFA type from the signInResponse
      const mfaType =
        this.state.signInResponse.nextStep?.signInStep ===
        "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
          ? "TOTP"
          : "SMS";

      console.log(`Confirming MFA with ${mfaType} code:`, confirmationCode);

      // Confirm the MFA code
      const confirmResult = await confirmSignIn({
        challengeResponse: confirmationCode,
      });

      console.log("MFA confirmation result:", confirmResult);

      if (confirmResult.isSignedIn) {
        // Fetch user data after successful MFA confirmation
        await this.getUserData();

        // Clear the signInResponse as it's no longer needed
        this.setState({
          signInResponse: null,
          loading: false,
        });

        return confirmResult;
      } else {
        console.error("MFA confirmation did not result in signed in state");
        this.setState({
          error: new Error("MFA confirmation failed"),
          loading: false,
        });
        throw new Error("MFA confirmation failed");
      }
    } catch (error) {
      console.error("Error confirming MFA:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async handleMFASetup(mfaMethod) {
    try {
      this.setState({ loading: true });
      console.log(`Setting up MFA with method: ${mfaMethod}`);
      await updateMFAPreference({ [mfaMethod]: "ENABLED" });
      const updatedMfaSettings = await fetchMFAPreference();
      await this.getUserData();
      console.log("MFA setup successful");

      this.setState({
        mfaSettings: updatedMfaSettings,
        loading: false,
      });

      return updatedMfaSettings;
    } catch (error) {
      console.error("MFA setup failed:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async setupTOTP() {
    try {
      this.setState({ loading: true });
      const totpSetupDetails = await setUpTOTP();
      const uri = totpSetupDetails.getSetupUri(
        "ALERTWest",
        this.state.userAttributes.email
      );
      console.log("TOTP setup URI generated");
      this.setState({ loading: false });
      return uri;
    } catch (error) {
      console.error("Failed to set up TOTP:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async verifyTOTPSetup(otpCode) {
    try {
      this.setState({ loading: true });
      await verifyTOTPSetup({ code: otpCode });
      this.setState({ loading: false });
      return true;
    } catch (error) {
      console.error("Failed to verify TOTP setup:", error);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  },

  async handleConfirmUserAttribute(userAttributeKey, confirmationCode) {
    try {
      await confirmUserAttribute({
        userAttributeKey,
        confirmationCode,
      });
      const userAttributes = await fetchUserAttributes();
      this.setState({
        userAttributes,
      });
    } catch (error) {
      console.error("Error confirming user attribute:", error);
      this.setState({ error });
      throw error;
    }
  },

  setError(error) {
    this.setState({ error });
  },

  setLoading(loading) {
    this.setState({ loading });
  },
};

// Bind all methods to the AuthState object
Object.keys(AuthState).forEach((key) => {
  if (typeof AuthState[key] === "function") {
    AuthState[key] = AuthState[key].bind(AuthState);
  }
});

export default AuthState;
