import React from "react";

import { TextField } from "@mui/material";

export default function ValidatedTextField(props) {
	const {
		inputValue,
		setInputValue,
		inputError,
		setInputError,
		pattern,
		isRequired,
		helpText,
		errorText,
		formError,
		TextFieldProps
	} = props;
	const [showError, setShowError] = React.useState(false);
	const [isValid, setIsValid] = React.useState(false);
	const [isFocused, setIsFocused] = React.useState(false);

	const handleOnChange = (e) => {
		setInputValue(e.target.value);
		if (pattern) {
			if ((formError && e.target.value === "" && isRequired) || (e.target.value !== "" && !pattern.test(e.target.value))) {
				setInputError(true);
				setIsValid(false);
			} else {
				if (isRequired && e.target.value !== "") {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
				setInputError(false);
				setShowError(false);
			}
		} else {
			if (isRequired && e.target.value !== "") {
				setIsValid(true);
			} else {
				setIsValid(false);
			}
		}
	}

	const handleOnFocus = () => {
		setIsFocused(true);
	}

	const handleOnBlur = () => {
		setShowError(inputError);
		setIsFocused(false);
	}

	return (
		<TextField
			{...TextFieldProps}
			value={inputValue}
			onChange={handleOnChange}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			helperText={showError && inputError ? errorText : helpText}
			error={showError && inputError}
			color={isValid ? "success" : "primary"}
			focused={isValid || isFocused}
			sx={{
				'mb': 2,
				'& .MuiOutlinedInput-root': {
					'& input': {
					  '&:-webkit-autofill': {
						'WebkitBoxShadow': 'none !important',
						'boxShadow': 'none !important',
						'WebkitTextFillColor': 'white !important',
						'caretColor': 'inherit !important',
						'borderRadius': 'inherit',
						'backgroundColor': 'transparent !important',
						'transition': 'background-color 5000s ease-in-out 0s',
						},
					},
				},
				...TextFieldProps?.sx
			}}
		/>
	)
}