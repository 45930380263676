import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';

const cognitoLocales = [
  { code: 'de', name: 'Deutsch' },
  { code: 'en-GB', name: 'English (UK)' },
  { code: 'en', name: 'English (US)' },
  { code: 'es', name: 'Español' },
  { code: 'es-ES', name: 'Español (España)' },
  { code: 'fr', name: 'Français' },
  { code: 'it', name: 'Italiano' },
  { code: 'ja', name: '日本語' },
  { code: 'ko', name: '한국어' },
  { code: 'pt-BR', name: 'Português (Brasil)' },
  { code: 'zh-CN', name: '中文 (简体)' },
  { code: 'zh-TW', name: '中文 (繁體)' }
];

export default function LocaleAutocomplete({ value, setValue, AutocompleteProps }) {
  const [localeOptions, setLocaleOptions] = useState([]);

  useEffect(() => {
    const options = cognitoLocales.map(locale => ({
      ...locale,
      group: locale.code.split('-')[0].toUpperCase()
    }));
    setLocaleOptions(options);

    // Set default value to browser's language if it's supported
    const browserLocale = navigator.language;
    const defaultLocale = options.find(option => option.code === browserLocale) || options.find(option => option.code === 'en');
    if (defaultLocale && !value) {
      setValue(defaultLocale);
    }
  }, []);

  return (
    <Autocomplete
      {...AutocompleteProps}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      options={localeOptions}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => `${option.name} (${option.code})`}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="body2">
            {option.name} ({option.code})
          </Typography>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Locale" />}
      disableClearable
    />
  );
}