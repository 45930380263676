import React from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";

dayjs.extend(utc)
dayjs.extend(timezone);

export default function TimeZoneAutocomplete(props) {
	const { value, setValue, AutocompleteProps } = props;
	const [timeZoneOptions, setTimeZoneOptions] = React.useState([]);
	
	React.useEffect(() => {
		const timeZones = Intl.supportedValuesOf('timeZone');
		const browserTimeZone = dayjs.tz.guess();
		const options = [];
		timeZones.forEach((timeZone) => {
			let timeZoneObject = {
				name: timeZone,
				region: timeZone.split("/")[0],
				offset: dayjs.tz(Date.now(), timeZone).format("Z")
			};
			options.push(timeZoneObject);
			if (timeZoneObject.name === browserTimeZone) {
				setValue(timeZoneObject);
			}
		});
		setTimeZoneOptions(options);
	}, []);

	return (
		<Autocomplete
			{...AutocompleteProps}
			value={value}
			onChange={(event, newValue) => setValue(newValue)}
			options={timeZoneOptions}
			groupBy={(option) => option.region}
			getOptionLabel={(option) => `${option.name} ${option.offset !== "+00:00" ? `(UTC${option.offset})` : "(UTC)"}`}
			renderOption={(props, option) => (
				<Box justifyContent="space-between !important" component="li" {...props}>
					<Typography variant="body2" sx={{overflowWrap: "anywhere"}}>{option.name} {option.offset !== "+00:00" ? `(UTC${option.offset})` : "(UTC)"}</Typography>
					<Paper sx={{ p: 0.5, backgroundImage: "unset" }}>
						<Typography variant="caption">
							{dayjs.tz(Date.now(), option.name).format("h:mmA")}
						</Typography>
					</Paper>
				</Box>
			)}
			renderInput={(params) => <TextField {...params} label="Time Zone" />}
			disableClearable
		/>
	)
}