import React from "react";

import { AuthContext } from "../../contexts/AuthContext";
import { SignInFlowStates } from "../../constants/SignInFlowStates";
import { AuthActions } from "../../constants/AuthActions";

import { Alert, Box, Button, CircularProgress, Divider, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import ValidatedTextField from "../misc/ValidatedTextField";
import { MuiPhone } from "../misc/MuiPhone";
import TimeZoneAutocomplete from "./TimeZoneAutocomplete";
import LocaleAutocomplete from "./LocaleAutocomplete";
import { confirmSignIn, sendUserAttributeVerificationCode } from "aws-amplify/auth";
import { Navigate, useNavigate } from "react-router-dom";

export default function AdminForceNewPasswordForm(props) {
	const { state, checkUser, handleAccountSetup } = React.useContext(AuthContext);
	const { signInResponse } = state;

	const [givenNameInput, setGivenNameInput] = React.useState("");
	const [givenNameInputError, setGivenNameInputError] = React.useState(false);
	const [familyNameInput, setFamilyNameInput] = React.useState("");
	const [familyNameInputError, setFamilyNameInputError] = React.useState(false);
	const [preferredUsernameInput, setPreferredUsernameInput] = React.useState("");
	const [preferredUsernameInputError, setPreferredUsernameInputError] = React.useState(false);
	const [phoneNumberInput, setPhoneNumberInput] = React.useState("");
	const [phoneNumberInputError, setPhoneNumberInputError] = React.useState(false);
	const [newPasswordInput, setNewPasswordInput] = React.useState("");
	const [newPasswordInputError, setNewPasswordInputError] = React.useState(false);
	const [confirmNewPasswordInput, setConfirmNewPasswordInput] = React.useState("");
	const [showNewPasswordInput, setShowNewPasswordInput] = React.useState(false);
	const [zoneInfo, setZoneInfo] = React.useState(null);
	const [localeInfo, setLocaleInfo] = React.useState(null);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [error, setError] = React.useState(false);
	const navigate = useNavigate();
	
	const handleCompleteSetup = async () => {
		try {
			setIsSubmitting(true);
			handleAccountSetup(newPasswordInput, {
				zoneinfo: zoneInfo.name,
				given_name: givenNameInput,
				family_name: familyNameInput,
				phone_number: phoneNumberInput,
				locale: localeInfo.code,
				preferred_username: preferredUsernameInput
			});
		} catch (error) {
			setError(error);
		}
		// Ensure submitting state is reset even on success
		setIsSubmitting(false);
	}

	// Verify that we have a valid signInResponse with required attributes
	React.useEffect(() => {
		console.log("AdminForceNewPasswordForm mounted with signInResponse:", signInResponse);
		
		if (!signInResponse || 
			signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" ||
			!signInResponse.nextStep?.missingAttributes) {
			console.error("Invalid or missing signInResponse for force new password flow:", signInResponse);
		}
	}, [signInResponse]);
	
	return (
		<Box 
			sx={{ 
				display: "flex", 
				justifyContent: "center",
				alignItems: "flex-start",
				width: "100%",
				height: "calc(100vh - 64px)",
				overflow: "auto",
				'&::-webkit-scrollbar': {
					width: '8px', // Thin scrollbar
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: 'transparent', // Transparent track
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white for dark mode
					borderRadius: '4px', // Rounded edges
				},
				'&::-webkit-scrollbar-thumb:hover': {
					backgroundColor: 'rgba(255, 255, 255, 0.3)', // Slightly brighter on hover
				},
				scrollbarWidth: 'thin', // Firefox support
				scrollbarColor: 'rgba(255, 255, 255, 0.2) transparent' // Firefox scrollbar colors
			}}
		>
			<Box 
				maxWidth={400} 
				mt={5}
				mb={5}
				sx={{ 
					width: "100%",
					padding: "0 16px"
				}}
			>
				<Paper>
					<Typography variant="h4" px={2} py={1}>Account Setup</Typography>
					<Divider />
					<Box p={3} display="flex" flexDirection="column" justifyContent="center">
						{error && <Alert severity="error" variant="outlined" sx={{mb: 2}}>
							There was an issue logging in. Refresh the page and sign in again.
						</Alert>}
						{signInResponse?.nextStep?.missingAttributes.includes("given_name") && (
							<ValidatedTextField
								inputValue={givenNameInput}
								setInputValue={setGivenNameInput}
								inputError={givenNameInputError}
								setInputError={setGivenNameInputError}
								isRequired={true}
								TextFieldProps={{
									disabled: isSubmitting || error,
									size: "small",
									type: "text",
									label: "First Name",
									margin: "normal",
									fullWidth: true,
									sx: {mt: 0}
								}}
							/>
						)}
						{signInResponse?.nextStep?.missingAttributes.includes("family_name") && (
							<ValidatedTextField
								inputValue={familyNameInput}
								setInputValue={setFamilyNameInput}
								inputError={familyNameInputError}
								setInputError={setFamilyNameInputError}
								isRequired={true}
								TextFieldProps={{
									disabled: isSubmitting || error,
									size: "small",
									type: "text",
									label: "Last Name",
									margin: "normal",
									fullWidth: true
								}}
							/>
						)}
						{signInResponse?.nextStep?.missingAttributes.includes("preferred_username") && (
							<ValidatedTextField
								inputValue={preferredUsernameInput}
								setInputValue={setPreferredUsernameInput}
								inputError={preferredUsernameInputError}
								setInputError={setPreferredUsernameInputError}
								isRequired={true}
								TextFieldProps={{
									disabled: isSubmitting || error,
									size: "small",
									type: "text",
									label: "Preferred Username",
									margin: "normal",
									fullWidth: true
								}}
							/>
						)}
						{signInResponse?.nextStep?.missingAttributes.includes("phone_number") && (
								<MuiPhone
								inputValue={phoneNumberInput}
								setInputValue={setPhoneNumberInput}
								inputError={phoneNumberInputError}
								setInputError={setPhoneNumberInputError}
								pattern={/^\+[1-9]\d{10,14}$/}
								isRequired={true}
								errorText="Invalid phone number."
								formError={error}
								TextFieldProps={{
									disabled: isSubmitting || error,
									size: "small",
									type: "text",
									label: "Phone Number (Must be verifiable via SMS)",
									margin: "normal",
									fullWidth: true
								}}
							/>
						)}
						<ValidatedTextField
							inputValue={newPasswordInput}
							setInputValue={(newValue) => {
								setNewPasswordInput(newValue);
								setConfirmNewPasswordInput(() => {return confirmNewPasswordInput})
							}}
							inputError={newPasswordInputError}
							setInputError={setNewPasswordInputError}
							pattern={/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
							isRequired={true}
							helpText="Password must be at least 8 characters and contain at least one number, one special character, one lowercase letter, and one uppercase letter."
							errorText="Password must be at least 8 characters and contain at least one number, one special character, one lowercase letter, and one uppercase letter."
							formError={error}
							TextFieldProps={{
								disabled: isSubmitting || error,
								size: "small",
								type: showNewPasswordInput ? "text" : "password",
								label: "New Password",
								margin: "normal",
								fullWidth: true,
								InputProps: {
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												tabIndex={-1}
												aria-label="toggle password visibility"
												size="small"
												onClick={() => setShowNewPasswordInput(!showNewPasswordInput)}
											>
												{showNewPasswordInput ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}
							}}
						/>
						<TextField
							value={confirmNewPasswordInput}
							onChange={(e) => {setConfirmNewPasswordInput(e.target.value)}}
							error={newPasswordInput !== "" && newPasswordInput !== confirmNewPasswordInput}
							color={newPasswordInput !== "" && newPasswordInput === confirmNewPasswordInput ? "success" : "primary"}
							focused={newPasswordInput !== "" && newPasswordInput === confirmNewPasswordInput}
							helperText={newPasswordInput !== "" && newPasswordInput !== confirmNewPasswordInput ? "Passwords must match." : false}
							disabled={isSubmitting || newPasswordInput === "" || error}
							size="small"
							type={showNewPasswordInput ? "text" : "password"}
							label="Confirm New Password"
							margin="normal"
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											tabIndex={-1}
											aria-label="toggle password visibility"
											size="small"
											onClick={() => setShowNewPasswordInput(!showNewPasswordInput)}
										>
											{showNewPasswordInput ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
							sx={{mt: 1, mb: 2}}
						/>
						{signInResponse?.nextStep?.missingAttributes.includes("zoneinfo") && (
							<TimeZoneAutocomplete
								value={zoneInfo}
								setValue={setZoneInfo}
								AutocompleteProps={{
									disabled: (isSubmitting || error !== false),
									size: "small",
									sx: {mt: 1, mb: 3}
								}}
							/>
						)}
						{signInResponse?.nextStep?.missingAttributes.includes("locale") && (
							<LocaleAutocomplete
								value={localeInfo}
								setValue={setLocaleInfo}
								AutocompleteProps={{
									disabled: (isSubmitting || error !== false),
									size: "small",
									sx: {mt: 1, mb: 3}
								}}
							/>
						)}
						<Button
							onClick={handleCompleteSetup}
							variant="contained"
							disabled={
								isSubmitting ||
								givenNameInputError ||
								familyNameInputError ||
								newPasswordInputError ||
								givenNameInput === "" ||
								familyNameInput === "" ||
								newPasswordInput === "" ||
								newPasswordInput !== confirmNewPasswordInput ||
								error
							}
							sx={{ mt: 2, mb: 2 }}
						>
							{isSubmitting ? <CircularProgress size={24} /> : "Complete Setup"}
						</Button>
					</Box>
				</Paper>
			</Box>
		</Box>
	);
}