import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SignInFlowStates } from "../../constants/SignInFlowStates";
import { AuthActions } from "../../constants/AuthActions";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import Loading from "../misc/Loading";

// Import all auth components
import AdminSignInForm from "./AdminSignInForm";
import AdminEmailVerificationForm from "./AdminEmailVerificationForm";
import AdminPhoneVerificationForm from "./AdminPhoneVerificationForm";
import AdminMFAWarning from "./AdminMFAWarning";
import AdminMFAForm from "./AdminMFAForm";
import AdminMFAChoice from "./AdminMFAChoice";
import AdminForceNewPasswordForm from "./AdminForceNewPasswordForm";

export default function LoginFlow() {
  const { state, restartFlow } = useContext(AuthContext);
  
  console.log(`Rendering LoginFlow with state: ${state.signInFlowState}, loading: ${state.loading}`);
  
  // Reusable error component for consistent error UI
  const ErrorComponent = ({ message, onReset }) => (
    <Box maxWidth={400} pt={5}>
      <Paper>
        <Typography variant="h4" px={2} py={1}>Authentication Error</Typography>
        <Divider />
        <Box p={2} textAlign="center">
          <Typography gutterBottom color="error">
            {message}
          </Typography>
          <Button 
            variant="contained" 
            onClick={onReset}
            sx={{ mt: 2 }}
          >
            Return to Sign In
          </Button>
        </Box>
      </Paper>
    </Box>
  );
  
  // Show loading indicator when authentication is being initialized
  if (state.loading) {
    return <Loading message="Initializing authentication..." />;
  }
  
  switch (state.signInFlowState) {
    case SignInFlowStates.INITIAL:
      return <AdminSignInForm />;
      
    case SignInFlowStates.CHECKING_VERIFICATION:
      return <Loading message="Checking verification status..." />;
      
    case SignInFlowStates.EMAIL_VERIFICATION:
      return <AdminEmailVerificationForm />;
      
    case SignInFlowStates.PHONE_VERIFICATION:
      return <AdminPhoneVerificationForm />;
      
    case SignInFlowStates.MFA_SETUP:
      return <AdminMFAWarning />;
      
    case SignInFlowStates.MFA_CHOICE:
      // Validate we have the required signInResponse for MFA choice
      if (!state.signInResponse || 
          state.signInResponse.nextStep?.signInStep !== "CONTINUE_SIGN_IN_WITH_MFA_SELECTION") {
        console.error("No valid signInResponse in MFA_CHOICE state:", state.signInResponse);
        return <ErrorComponent 
          message="Missing MFA selection data. Please sign in again." 
          onReset={restartFlow} 
        />;
      }
      return <AdminMFAChoice />;
      
    case SignInFlowStates.MFA_CHALLENGE:
      // Validate we have the required signInResponse for MFA challenge
      if (!state.signInResponse || 
          (state.signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_SMS_CODE" && 
           state.signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_TOTP_CODE" &&
           state.signInResponse.nextStep?.signInStep !== "CONTINUE_SIGN_IN_WITH_MFA_SELECTION")) {
        console.error("No valid signInResponse in MFA_CHALLENGE state:", state.signInResponse);
        return <ErrorComponent 
          message="Missing MFA challenge data. Please sign in again." 
          onReset={restartFlow} 
        />;
      }
      return <AdminMFAForm />;

    case SignInFlowStates.ACCOUNT_SETUP:
      // Validate we have the required signInResponse for ACCOUNT_SETUP
      if (!state.signInResponse || 
          state.signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        console.error("No valid signInResponse in ACCOUNT_SETUP state:", state.signInResponse);
        return <ErrorComponent 
          message="Missing required data for new password setup. Please sign in again." 
          onReset={restartFlow} 
        />;
      }
      return <AdminForceNewPasswordForm />;
      
    case SignInFlowStates.AUTHENTICATED:
      return <Navigate to="/" replace />;
      
    case SignInFlowStates.ERROR:
      return <ErrorComponent 
        message="Something went wrong with the authentication flow." 
        onReset={restartFlow} 
      />;
      
    default:
      return <div>Unknown state: {state.signInFlowState}</div>;
  }
}