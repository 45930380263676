import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";

import { Alert, Box, Button, CircularProgress, Divider, FormControl, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ValidatedTextField from "../misc/ValidatedTextField";
import Loading from "../misc/Loading";

export default function AdminSignInForm(props) {
	const { state, handleSignIn } = useContext(AuthContext);
	const [usernameInput, setUsernameInput] = useState("");
	const [usernameInputError, setUsernameInputError] = useState(false);
	const [passwordInput, setPasswordInput] = useState("");
	const [passwordInputError, setPasswordInputError] = useState(false);
	const [showPasswordInput, setShowPasswordInput] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [localError, setLocalError] = useState(null);
	const navigate = useNavigate();
	
	// Clear error message when user makes changes to inputs
	useEffect(() => {
		if (localError) {
			setLocalError(null);
		}
	}, [usernameInput, passwordInput]);

	const handleShowPasswordInputToggle = () => {
		setShowPasswordInput(!showPasswordInput);
	}

	const signIn = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			await handleSignIn(usernameInput, passwordInput);
		} catch (error) {
			console.log(`Caught error: ${error}`);
			setLocalError(error);
		}
		setIsSubmitting(false);
	}

	// Show loading state when global loading is true
	if (state.loading) {
		return <Loading message="Initializing..." />;
	}

	// Navigation is now handled by the parent LoginFlow component
	return (
		<Box maxWidth={400} pt={5}>
			<Paper>
				<Typography variant="h4" px={2} py={1}>Sign In</Typography>
				<Divider />
				<form onSubmit={signIn}>
					<FormControl>
						<Box p={2}>
							{localError && <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
								{localError.recoverable 
									? localError.message
									: "There was an issue logging in. Please try again later or contact support."}
							</Alert>}
							<ValidatedTextField
								inputValue={usernameInput}
								setInputValue={setUsernameInput}
								inputError={usernameInputError}
								setInputError={setUsernameInputError}
								pattern={/(^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$)|(^\+[1-9]\d{1,14}$)/}
								isRequired={true}
								errorText="Please enter a valid email address."
								formError={localError}
								TextFieldProps={{
									disabled: isSubmitting,
									size: "small",
									type: "text",
									label: "Email Address",
									placeholder: "Email",
									margin: "normal",
									fullWidth: true,
								}}
							/>
							<ValidatedTextField
								inputValue={passwordInput}
								setInputValue={setPasswordInput}
								inputError={passwordInputError}
								setInputError={setPasswordInputError}
								pattern={/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
								isRequired={true}
								errorText="Password must be at least 8 characters and contain at least one number, one special character, one lowercase letter, and one uppercase letter."
								formError={localError}
								TextFieldProps={{
									disabled: isSubmitting,
									size: "small",
									type: showPasswordInput ? "text" : "password",
									label: "Password",
									margin: "normal",
									fullWidth: true,
									InputProps: {
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													size="small"
													onClick={handleShowPasswordInputToggle}
												>
													{showPasswordInput ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									},
									sx: { mb: 2 }
								}}
							/>
							<Box display="flex" justifyContent="space-between">
								<Button 
									type="submit" 
									variant="contained" 
									disabled={isSubmitting || usernameInputError || passwordInputError || usernameInput === "" || passwordInput === ""}
								>
									{isSubmitting ? <CircularProgress size={24} /> : "Sign In"}
								</Button>
								<Button variant="text" onClick={() => navigate("/login/recovery")}>
									Forgot Password
								</Button>
							</Box>
						</Box>
					</FormControl>
				</form>
			</Paper>
		</Box>
	);
}