import React from "react";
import { Container } from "@mui/material";
import { confirmUserAttribute, sendUserAttributeVerificationCode, fetchUserAttributes } from "aws-amplify/auth";
import { AuthContext } from "../../contexts/AuthContext";
import { SignInFlowStates } from "../../constants/SignInFlowStates";
import { AuthActions } from "../../constants/AuthActions";
import { useNavigate } from "react-router-dom";
import VerificationForm from "./VerificationForm";
import Loading from "../misc/Loading";

export default function AdminPhoneVerificationForm(props) {
	const { state } = React.useContext(AuthContext);
	const { userAttributes } = state;
	const navigate = useNavigate();

	const confirmUserAttribute = async (code) => {
		await handleConfirmUserAttribute("phone_number", code);
	}

	const handleResend = async () => {
		try {
			await sendUserAttributeVerificationCode({ userAttributeKey: "phone_number" });
			return true;
		} catch (error) {
			console.error("Error sending new phone verification code:", error);
			throw error;
		}
	}

	// Show loading state if userAttributes is null
	if (!userAttributes) {
		return <Loading message="Loading user data..." />;
	}

	React.useEffect(() => {
		sendUserAttributeVerificationCode({ userAttributeKey: "phone_number" });
	}, []);

	return (
		<Container sx={{ display: "flex", justifyContent: "center" }}>
			<VerificationForm 
				type="phone" 
				onVerify={confirmUserAttribute} 
				onResend={handleResend} 
				destination={userAttributes.phone_number}
			/>
		</Container>
	);
}