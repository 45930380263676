export const SignInFlowStates = {
  INITIAL: "initial", // Starting point: Sign-in form
  EMAIL_VERIFICATION: "email_verification", // Verify email if not verified
  PHONE_VERIFICATION: "phone_verification", // Verify phone if required
  MFA_SETUP: "mfa_setup", // Prompt to set up MFA (optional step)
  MFA_CHALLENGE: "mfa_challenge", // MFA code entry during sign-in
  ACCOUNT_SETUP: "account_setup", // First login or password reset
  AUTHENTICATED: "authenticated", // Successfully signed in
  ERROR: "error", // Handle errors in the flow
  MFA_CHOICE: "mfa_choice", // MFA method selection
};
