import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { SignInFlowStates } from "../../constants/SignInFlowStates";
import { AuthActions } from "../../constants/AuthActions";
import { Typography, Box, Button, Paper, RadioGroup, Radio, FormControlLabel, CircularProgress } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { PhoneIphone, Email, Apps } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import AwLogo from '../../assets/images/aw_logo_white.svg';
import Loading from "../misc/Loading";

export default function AdminMFAWarning() {
  const {
    state,
    handleMFASetup,
    handleMFASetupSkipped,
    setupTOTP,
    verifyTOTPSetup,
  } = useContext(AuthContext);
  
  const { user, userAttributes, mfaSettings } = state;

  const [step, setStep] = useState('initial');
  const [selectedMfaMethod, setSelectedMfaMethod] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [setupUri, setSetupUri] = useState('');
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [isOtpVerifying, setIsOtpVerifying] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);

  useEffect(() => {
    if (selectedMfaMethod === 'totp' && step === 'confirmation') {
      handleTotpSetup();
    }
  }, [selectedMfaMethod, step]);

  const handleEnableMfa = () => setStep('selection');
  const handleSkipMfa = () => {
    handleMFASetupSkipped();
  };
  const handleCancelMfa = () => setStep('initial');

  const handleMfaMethodSelect = (method) => {
    setSelectedMfaMethod(method);
    setStep('confirmation');
  };

  const handleMfaSetup = async () => {
    try {
      setIsSubmitting(true);
      console.log(`Setting up MFA with method: ${selectedMfaMethod}`);
      const updatedMfaSettings = await handleMFASetup(selectedMfaMethod);
      console.log("MFA setup successful, redirecting to home page");
    } catch (error) {
      console.error("MFA setup failed:", error);
      setError(`Failed to set up MFA: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    const lastFour = phoneNumber.slice(-4);
    return `(***) ***-${lastFour}`;
  };

  const maskEmail = (email) => {
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
    return `${maskedUsername}@${domain}`;
  };

  const handleTotpSetup = async () => {
    try {
      const uri = await setupTOTP();
      console.log(uri);
      setSetupUri(uri);
    } catch (error) {
      setError(`Failed to set up TOTP: ${error.message}`);
    }
  };

  const handleTotpChange = (otp) => {
    setOtp(otp.replace(/[^0-9]/g, ""));
  };

  const handleVerifyTotpSetup = async (otpCode) => {
    try {
      setIsOtpVerifying(true);
      console.log(otpCode);
      await verifyTOTPSetup(otpCode);
      setIsOtpValid(true);
      setOtpError(false);
    } catch (error) {
      if (error.message === "Code mismatch") {
        setOtpError(true);
      } else {
        setError(`Failed to verify TOTP setup: ${error.message}`);
      }
    } finally {
      setIsOtpVerifying(false);
    }
  };

  const validateChar = (char) => {
	const isNumber = typeof char === "number";
	return (isNumber || char !== "") && !isNaN(Number(char));
  };

  const mfaOptions = [
	{ value: 'totp', label: 'Authenticator App', icon: <Apps /> },
    { value: 'email', label: 'Email', icon: <Email /> },
	{ value: 'sms', label: 'SMS', icon: <PhoneIphone /> },
  ];

  // Navigation is now handled by the parent LoginFlow component
  // No need for Navigate components

  const renderMfaSelection = () => (
    <Box maxWidth={500} pt={5}>
      <Paper sx={{ p: 2 }}>
        <Typography gutterBottom>
          Select a method to enable multi-factor authentication:
        </Typography>
        <RadioGroup value={selectedMfaMethod} onChange={(e) => setSelectedMfaMethod(e.target.value)}>
          {mfaOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                	{option.icon}
                	<Typography sx={{ marginLeft: 1, color: option.disabled ? 'text.disabled' : 'text.primary' }}>
						{option.label}
					</Typography>
                </Box>
              }
			  disabled={option.disabled}
            />
          ))}
        </RadioGroup>
        <Box pt={1} display="flex" justifyContent="space-between">
          <Button 
            variant="contained" 
            onClick={() => handleMfaMethodSelect(selectedMfaMethod)} 
            disabled={!selectedMfaMethod}
          >
            Continue
          </Button>
          <Button color="secondary" onClick={handleCancelMfa}>Cancel</Button>
        </Box>
      </Paper>
    </Box>
  );

  const renderMfaConfirmation = () => {
    let content;
    switch (selectedMfaMethod) {
      case 'sms':
        content = (
          <>
            <PhoneIphone sx={{ height: "72px", width: "72px" }} />
            <Typography mt={1}>
              The phone number currently associated with your account is:
            </Typography>
            {
				userAttributes && 
				<Box
					backgroundColor="black"
					borderRadius={1}
					p={1}
					m={2}
				>
					<Typography variant="h5" fontWeight={400} letterSpacing={2}>
						{maskPhoneNumber(userAttributes.phone_number)}
					</Typography>
				</Box>
			}
            <Typography>Do you want to enable SMS multi-factor authentication for this number?</Typography>
          </>
        );
        break;
      case 'email':
        content = (
          <>
            <Email sx={{ height: "72px", width: "72px" }} />
            <Typography mt={1}>
              The email address currently associated with your account is:
            </Typography>
            {
				userAttributes && 
				<Box
					backgroundColor="black"
					borderRadius={1}
					p={1}
					m={2}
				>
					<Typography variant="h5" fontWeight={400} letterSpacing={1.1}>
						{maskEmail(userAttributes.email)}
					</Typography>
				</Box>
			}
            <Typography>Do you want to enable email multi-factor authentication for this address?</Typography>
          </>
        );
        break;
      case 'totp':
        content = (
          <>
            {setupUri ? (
              <Box mb={2}>
                <QRCode 
                  value={setupUri.href} 
                  size={200}
                  qrStyle="dots"
                  eyeRadius={2}
                //   logoImage={AwLogo}
                //   logoWidth={86.8}
                //   logoHeight={50}
				//   logoPadding={5}
				//   logoPaddingStyle="circle"
				  bgColor="#ffffff"
				  fgColor="#000000"
				  removeQrCodeBehindLogo={true}
                />
              </Box>
            ) : (
				<Box mb={2} minHeight={200}>
            		<CircularProgress />
				</Box>
            )}
            <Typography mt={1}>
              Scan the QR code with an authenticator app such as Google Authenticator or Microsoft Authenticator and enter the code below.
            </Typography>
			<Box p={2}>
				<MuiOtpInput
					autoFocus
					length={6}
					TextFieldsProps={{ 
						placeholder: '-', 
						error: otpError, 
						sx: {
							'& .MuiOutlinedInput-root': {
							  '& fieldset': {
								borderColor: isOtpValid ? 'green' : (otpError ? 'red' : 'inherit'),
							  },
							  '&:hover fieldset': {
								borderColor: isOtpValid ? 'green' : (otpError ? 'red' : 'inherit'),
							  },
							  '&.Mui-focused fieldset': {
								borderColor: isOtpValid ? 'green' : (otpError ? 'red' : 'primary.main'),
							  },
							  '&.Mui-disabled': {
								'& fieldset': {
									borderColor: isOtpValid ? 'green !important' : 'rgba(0, 0, 0, 0.26)',
  								},
  								'& input': {
  								  color: isOtpValid ? 'green !important' : 'rgba(0, 0, 0, 0.38)',
  								  '-webkit-text-fill-color': isOtpValid ? 'green !important' : 'rgba(0, 0, 0, 0.38)',
  								},
							  },
							},
						},
						disabled: isOtpVerifying || isOtpValid,
					}}
					value={otp}
					onChange={handleTotpChange}
					validateChar={validateChar}
					onComplete={handleVerifyTotpSetup}
				/>
			</Box>
			{isOtpVerifying && <CircularProgress />}
			{otpError && <Typography color="error">Invalid code. Please try again.</Typography>}
			{isOtpValid && <Typography>Code verified successfully.</Typography>}
          </>
        );
        break;
      default:
        return null;
    }

    return (
      <Box maxWidth={500} pt={5}>
        <Paper sx={{ p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
            {content}
          </Box>
          <Box pt={1} display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={handleMfaSetup} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
            <Button color="secondary" onClick={() => setStep('selection')}>Go Back</Button>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderInitialPrompt = () => (
    <Box maxWidth={500} pt={5}>
      <Paper sx={{ p: 2 }}>
        <Typography gutterBottom>
          You do not currently have a multi-factor authentication preference set. By default, we require at least SMS MFA. If you would like to set a different method, you may do so now.
        </Typography>
        <Box pt={1} display="flex" justifyContent="space-between">
          <Button variant="contained" onClick={handleEnableMfa} disabled={isSubmitting}>Set preference</Button>
          <Button color="secondary" onClick={handleSkipMfa} disabled={isSubmitting}>Skip for now</Button>
        </Box>
      </Paper>
    </Box>
  );

  // Show loading state if userAttributes is null
  if (!userAttributes) {
    return <Loading message="Loading user data..." />;
  }

  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      {step === 'initial' && renderInitialPrompt()}
      {step === 'selection' && renderMfaSelection()}
      {step === 'confirmation' && renderMfaConfirmation()}
    </>
  );
}