import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
axios.defaults.baseURL = "http://localhost:8080";

export async function authedFetch(url, options = {}) {
    const session = await fetchAuthSession();

    let result = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': session.tokens?.idToken?.toString(),
        }
    })
    return result;
}

export async function getStaticDefaultCamCounties() {
    let result = await axios.get('/secure/admin/api/defaultCamCounties/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultCamISPs() {
    let result = await axios.get('/secure/admin/api/defaultCamISPs/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultCamSponsors() {
    let result = await axios.get('/secure/admin/api/defaultCamSponsors/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultCamStates() {
    let result = await axios.get('/secure/admin/api/defaultCamStates/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultAgencies() {
    let result = await axios.get('/secure/admin/api/defaultAgencies/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultCameraStates() {
    let result = await axios.get('/secure/admin/api/defaultCameraStates/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultCountries() {
    let result = await axios.get('/secure/admin/api/defaultCountries/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultFeatures() {
    let result = await axios.get('/secure/admin/api/defaultFeatures/get')
		.then(function (response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
		.catch(function (error) {
			console.log(error);
            return false;
        })
    return result;
}

export async function getStaticDefaultGroupStates() {
    let result = await axios.get('/secure/admin/api/defaultGroupStates/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultGroupNames() {
    let result = await axios.get('/secure/admin/api/defaultGroupNames/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultGroups() {
    let result = await axios.get('/secure/admin/api/defaultGroups/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultGroupUsers(groupId) {
    console.log("getStaticDefaultGroupUsers", groupId);
    let result = await axios.get('/secure/admin/api/defaultGroupUsers/get', {
            params: {
                groupId: groupId
            }
        })
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export async function getStaticDefaultIndustries() {
    let result = await axios.get('/secure/admin/api/defaultIndustries/get')
        .then(function(response) {
            if(response.data.code === 1) {
                return response.data.data;
            }
        })
        .catch(function (error) {
            return false;
        })
    return result;
}

export function isEditable(elThres, uThresh) {
    if((undefined === elThres && undefined === uThresh) || (undefined !== elThres && undefined !== uThresh && uThresh >= elThres)) {
        return true;
    } else {
        return false;
    }
}

export function getObjectIndexValue(object,index,keyType) {
	var str = "";
	for(var i in object) {
		if(i === index) {
			str = object[i];
		}
	}

	if(undefined === str || null === str || "" === str || str === "-") {
		if(keyType === "int") {
			str = -99999999;
		} else {
			str = "zzzzzzzz";
		}
	}
	return str;
}

export function sortByKey(arrayToSort, arrayKey, keyType, order) {				//takes an input array, sorts it by the specified array key; needs to know the key type (int or str) and which order is being requested because reasons
	return arrayToSort.sort(function(a,b) {								//returns the result of this anonymous function
		var val = true;													//default true/1
		var type = typeof a;											//get the type of we're working with
		if(type === "object") {											//if the type is an object
			var x1 = getObjectIndexValue(a,arrayKey,keyType);			//get the object index value and set x
			var y1 = getObjectIndexValue(b,arrayKey,keyType);			//get the object inddex value and set y
		} else if(type === "array") {									//if the type is an array
			var x1 = (a[arrayKey] !== null ? a[arrayKey] : "");			//set x, replacing nulls with ""
			var y1 = (b[arrayKey] !== null ? b[arrayKey] : "");			//set y, replacing nulls with ""
		} else {
			alert("Error: unable to determine if working with an object or array");	//alert
			return;														//and return
		}
		if(keyType === "int") {											//if the keytype is int...well, we're going to process as floats
			var x2 = parseFloat(x1);										//cast x to a float
			var y2 = parseFloat(y1);										//cast y to a float
			if(order === 1) {											//if order is set to 1
				if(y2 < x2) {											//and y is less than x
					val = -1;											//return -1
				} else if(y2 > x2) {									//if y is greater than x
					val = 1;											//return 1
				} else {												//otherwise they're equal
					val = 0;											//return 0
				}
			} else {													//if order doesn't equal 1 (presumably, 0)
				if(x2 < y2) {											//if x is less than y
					val = -1;											//return -1
				} else if(x2 > y2) {									//if x is greater than 1
					val = 1;											//return 1
				} else {												//otherwise they're equal
					val = 0;											//return 0
				}
			}
		} else {														//if it's not an int, it's prolly a string...
			var x2 = x1.toUpperCase();
			var y2 = y1.toUpperCase();
			if(order === 1) {											//if order is set to 1
				if(y2 > x2) {											//and y is greater than x
					val = -1;											//return -1
				} else if(y2 < x2) {									//if y is less than x
					val = 1;											//return 1
				} else {												//otherwise they're equal
					val = 0;											//return 0
				}
			} else {													//if order doesn't equal 1 (0?)
				if(x2 > y2) {											//if x is greater than y
					val = -1;											//return -1
				} else if(x2 < y2) {									//if x is less than y
					val = 1;											//return 1
				} else {												//otherwise they're equal
					val = 0;											//return 0
				}
			}
		}
		return val;														//return the value we determined
	});
}

export function formatTime(time, month, day, year, hours, minutes, seconds, ampm) {
	var str = "";
	let d = new Date(time * 1000);
	let staticMonths = Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
	str += (month == 1 ? staticMonths[d.getMonth()] + " " : "");
	str += (day == 1 ? d.getDate() : "") + (day == 1 && year == 1 && month == 1 ? ", " : " ");
	str += (year == 1 ? d.getFullYear() + " " : "");
	str += (hours == 1 ? (ampm == 1 && d.getHours() > 12 ? d.getHours() - 12 : (d.getHours()+'').padStart(2,'0')) : "");
	str += (minutes == 1 ? (hours == 1 ? ":" : "") + (d.getMinutes() <= 9 ? "0" : "") + d.getMinutes() : "");
	str += (seconds == 1 ? (hours == 1 || minutes == 1 ? ":" : "") + (d.getSeconds() <= 9 ? "0" : "") + d.getSeconds() : "");
	str += (ampm == 1 ? (d.getHours() >= 12 ? "pm" : "am") : "");
	return str;
}