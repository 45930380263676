import { createContext, useReducer, useEffect, useCallback } from "react";
import { SignInFlowStates } from "../constants/SignInFlowStates";
import { AuthActions } from "../constants/AuthActions";

// Initial state for the authentication flow
const initialState = {
  signInFlowState: SignInFlowStates.INITIAL,
  user: null,
  userAttributes: null,
  mfaSettings: null,
  signInResponse: null,
  loading: true,
  error: null,
  mfaSetupSkipped: false, // Flag to track if MFA setup was intentionally skipped
};

/**
 * Authentication State Machine
 *
 * States:
 * - INITIAL: Starting point, displays sign-in form.
 *   - Supported actions:
 *     - SIGN_IN: Initiates the sign-in process, transitioning to:
 *       - Any verification state (EMAIL_VERIFICATION, PHONE_VERIFICATION, MFA_SETUP) based on explicit request
 *       - Appropriate verification state or AUTHENTICATED when auto-detecting verification needs
 *       - MFA_CHALLENGE or ACCOUNT_SETUP for sign-ins requiring additional verification
 *       - ERROR for unknown sign-in steps
 *
 * - EMAIL_VERIFICATION: Prompts user to verify email if unverified.
 *   - Supported actions:
 *     - EMAIL_VERIFIED: Confirms email verification, transitioning to:
 *       - Any verification state or AUTHENTICATED based on explicit request
 *       - Auto-detected next state if no explicit request (typically AUTHENTICATED or another verification)
 *     - SET_ERROR: Transitions to:
 *       - ERROR state on critical failures
 *
 * - PHONE_VERIFICATION: Prompts user to verify phone if unverified.
 *   - Supported actions:
 *     - PHONE_VERIFIED: Confirms phone verification, transitioning to:
 *       - Any verification state or AUTHENTICATED based on explicit request
 *       - Auto-detected next state if no explicit request (typically MFA_SETUP or AUTHENTICATED)
 *     - SET_ERROR: Transitions to:
 *       - ERROR state on critical failures
 *
 * - MFA_SETUP: Prompts user to set up MFA if not enabled.
 *   - Supported actions:
 *     - MFA_SETUP_COMPLETE: Completes MFA setup, transitioning to:
 *       - AUTHENTICATED
 *     - MFA_SKIPPED: Skips MFA setup, transitioning to:
 *       - AUTHENTICATED and sets mfaSetupSkipped flag
 *     - SET_ERROR: Transitions to:
 *       - ERROR state on critical failures
 *
 * - MFA_CHALLENGE: Prompts for MFA code during sign-in if required.
 *   - Supported actions:
 *     - MFA_CONFIRMED: Confirms MFA code, transitioning to:
 *       - AUTHENTICATED
 *     - SET_ERROR: Transitions to:
 *       - ERROR state on critical failures
 *
 * - ACCOUNT_SETUP: Prompts for setting up account details.
 *   - Supported actions:
 *     - ACCOUNT_SETUP_CONFIRMED: Confirms new password, transitioning to:
 *       - EMAIL_VERIFICATION or any other verification state based on user needs
 *     - SET_ERROR: Transitions to:
 *       - ERROR state on critical failures
 *
 * - AUTHENTICATED: User is fully signed in and verified, redirects to app.
 *   - Supported actions: None; this is a final state
 *
 * - ERROR: Handles errors, allows reset to INITIAL.
 *   - Supported actions:
 *     - RESET: Resets the flow, transitioning to:
 *       - INITIAL state
 */

/**
 * Determines the next authentication state based on user attributes and MFA settings
 * Allows direct routing to the appropriate verification stage without requiring a strict sequence
 */
export function determineNextState(
  userAttributes,
  mfaSettings,
  mfaSetupSkipped
) {
  if (!userAttributes) {
    console.log(
      "No user attributes available, defaulting to AUTHENTICATED state"
    );
    return SignInFlowStates.AUTHENTICATED;
  }

  try {
    // Check verification statuses - handle potential missing attributes safely
    const needsEmailVerification = userAttributes.email_verified !== "true";
    const hasPhone = !!userAttributes.phone_number;
    const needsPhoneVerification =
      hasPhone && userAttributes.phone_number_verified !== "true";
    const needsMfaSetup =
      mfaSettings &&
      (mfaSettings.enabled === undefined || mfaSettings.enabled.length === 0) &&
      !mfaSetupSkipped;

    // Log all verification statuses for debugging
    console.log("Verification status:", {
      needsEmailVerification,
      hasPhone,
      needsPhoneVerification,
      needsMfaSetup,
      mfaSetupSkipped,
    });

    // Determine next state based on verification needs - can go to any required verification state
    if (needsEmailVerification) {
      console.log("Email not verified, transitioning to EMAIL_VERIFICATION");
      return SignInFlowStates.EMAIL_VERIFICATION;
    }

    if (needsPhoneVerification) {
      console.log("Phone not verified, transitioning to PHONE_VERIFICATION");
      return SignInFlowStates.PHONE_VERIFICATION;
    }

    if (needsMfaSetup) {
      console.log("MFA not enabled, transitioning to MFA_SETUP");
      return SignInFlowStates.MFA_SETUP;
    }

    console.log(
      "All required verifications complete, transitioning to AUTHENTICATED"
    );
    return SignInFlowStates.AUTHENTICATED;
  } catch (error) {
    console.error("Error in determineNextState:", error);
    // Default to authenticated if we encounter an error
    return SignInFlowStates.AUTHENTICATED;
  }
}

// Reducer to handle state transitions
function authReducer(state, action) {
  console.log(
    `Auth Reducer - Current State: ${state.signInFlowState}, Action: ${action.type}`
  );

  // Handle actions that apply to any state
  if (action.type === AuthActions.SET_LOADING) {
    return { ...state, loading: action.payload };
  }

  if (action.type === AuthActions.SET_ERROR) {
    return {
      ...state,
      signInFlowState: action.signInFlowState || SignInFlowStates.ERROR,
      error: action.payload,
    };
  }

  if (action.type === AuthActions.RESET) {
    return {
      ...initialState,
      signInFlowState: SignInFlowStates.INITIAL,
    };
  }

  switch (state.signInFlowState) {
    case SignInFlowStates.INITIAL:
      switch (action.type) {
        case AuthActions.SIGN_IN:
          const { signInResponse } = action.payload;

          // Check the sign-in response to determine the next state
          if (signInResponse.isSignedIn) {
            // User is signed in, determine the next state based on verification needs
            const { userAttributes, mfaSettings } = action.authData.getState();
            const verificationType = action.payload.verificationType;

            // If a specific verification type is provided, go to that verification state
            // Otherwise use the auto-detection logic to determine the next state
            const nextState = determineNextState(
              userAttributes,
              mfaSettings,
              state.mfaSetupSkipped
            );

            return {
              ...state,
              signInFlowState: nextState,
            };
          } else if (
            signInResponse.nextStep?.signInStep ===
              "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
            signInResponse.nextStep?.signInStep ===
              "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
          ) {
            return {
              ...state,
              signInFlowState: SignInFlowStates.MFA_CHALLENGE,
              signInResponse,
            };
          } else if (
            signInResponse.nextStep?.signInStep ===
            "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
          ) {
            return {
              ...state,
              signInFlowState: SignInFlowStates.MFA_CHOICE,
              signInResponse,
            };
          } else if (
            signInResponse.nextStep?.signInStep ===
            "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
          ) {
            return {
              ...state,
              signInFlowState: SignInFlowStates.ACCOUNT_SETUP,
              signInResponse,
            };
          } else {
            return {
              ...state,
              signInFlowState: SignInFlowStates.ERROR,
              error: new Error(
                `Unknown sign-in step: ${signInResponse.nextStep?.signInStep}`
              ),
            };
          }
        default:
          return state;
      }

    case SignInFlowStates.EMAIL_VERIFICATION:
      switch (action.type) {
        case AuthActions.EMAIL_VERIFIED:
          const { userAttributes, mfaSettings } = action.authData.getState();

          // Otherwise, auto-detect the next state
          const nextState = determineNextState(
            userAttributes,
            mfaSettings,
            state.mfaSetupSkipped
          );
          return {
            ...state,
            signInFlowState: nextState,
          };
        default:
          return state;
      }

    case SignInFlowStates.PHONE_VERIFICATION:
      switch (action.type) {
        case AuthActions.PHONE_VERIFIED:
          const { userAttributes, mfaSettings } = action.authData.getState();

          // Otherwise, auto-detect the next state
          const nextState = determineNextState(
            userAttributes,
            mfaSettings,
            state.mfaSetupSkipped
          );
          return {
            ...state,
            signInFlowState: nextState,
          };
        default:
          return state;
      }

    case SignInFlowStates.MFA_SETUP:
      switch (action.type) {
        case AuthActions.MFA_SETUP_COMPLETE:
          return {
            ...state,
            signInFlowState: SignInFlowStates.AUTHENTICATED,
          };
        case AuthActions.MFA_SKIPPED:
          return {
            ...state,
            signInFlowState: SignInFlowStates.AUTHENTICATED,
            mfaSetupSkipped: true,
          };
        default:
          return state;
      }

    case SignInFlowStates.MFA_CHOICE:
      switch (action.type) {
        case AuthActions.MFA_CHOICE:
          console.log("MFA choice action:", action);
          return {
            ...state,
            signInFlowState: SignInFlowStates.MFA_CHALLENGE,
            signInResponse: action.payload,
          };
        default:
          return state;
      }

    case SignInFlowStates.MFA_CHALLENGE:
      switch (action.type) {
        case AuthActions.MFA_CONFIRMED:
          const { userAttributes, mfaSettings } = action.authData.getState();
          const nextState = determineNextState(
            userAttributes,
            mfaSettings,
            state.mfaSetupSkipped
          );
          return {
            ...state,
            signInFlowState: nextState,
          };
        default:
          return state;
      }

    case SignInFlowStates.ACCOUNT_SETUP:
      switch (action.type) {
        case AuthActions.ACCOUNT_SETUP_CONFIRMED:
          const { userAttributes, mfaSettings, signInResponse } =
            action.authData.getState();

          let nextState;
          if (
            signInResponse.nextStep?.signInStep ===
              "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
            signInResponse.nextStep?.signInStep ===
              "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
          ) {
            nextState = SignInFlowStates.MFA_CHALLENGE;
          } else if (signInResponse.isSignedIn) {
            // Auto-detect the next verification state if we have user attributes
            nextState = determineNextState(
              userAttributes,
              mfaSettings,
              state.mfaSetupSkipped
            );
          }

          return {
            ...state,
            signInFlowState: nextState,
          };
        default:
          return state;
      }

    case SignInFlowStates.ERROR:
      switch (action.type) {
        case AuthActions.RESET:
          return {
            ...state,
            signInFlowState: SignInFlowStates.INITIAL,
            error: null,
          };
        default:
          return state;
      }

    default:
      return state;
  }
}

export const AuthContext = createContext({
  state: initialState,
  dispatch: () => {},
  checkUser: () => {},
  handleSignIn: () => {},
  confirmMFA: () => {},
  confirmNewPassword: () => {},
});

// AuthProvider for managing authentication state
export const AuthProvider = ({ children, authData }) => {
  // Use the reducer to maintain the state machine for this microfrontend
  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    signInFlowState: authData.state.isAuthenticated
      ? determineNextState(
          authData.state.userAttributes,
          authData.state.mfaSettings,
          false
        )
      : SignInFlowStates.INITIAL,
  });

  // Function to check and update user status
  const checkUser = async () => {
    try {
      // Use the centralized AuthState to get user data
      return await authData.getUserData();
    } catch (error) {
      console.error("Error in checkUser:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  // Function to handle sign-in
  const handleSignIn = async (username, password) => {
    try {
      const signInResponse = await authData.handleSignIn(username, password);

      // Update the local state machine based on the sign-in response
      dispatch({
        type: AuthActions.SIGN_IN,
        payload: { signInResponse },
        authData,
      });
    } catch (error) {
      console.log(error.name);
      const isRecoverable =
        error.name === "NotAuthorizedException" ||
        error.name === "UserNotFoundException";
      console.log(isRecoverable);

      if (isRecoverable) {
        throw error;
      } else {
        console.error("Sign in error:", error);
        dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
        throw error;
      }
    }
  };

  // Function to handle new password setup for admin-created users
  const handleAccountSetup = async (newPassword, userAttributes) => {
    try {
      // Use the centralized AuthState to handle account setup with new password
      const response = await authData.handleAccountSetup(
        newPassword,
        userAttributes
      );

      // Update the local state machine based on the response
      dispatch({
        type: AuthActions.ACCOUNT_SETUP_CONFIRMED,
        payload: { response },
        authData,
      });

      return;
    } catch (error) {
      console.error("New password setup error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  const handleMFAChoice = async (mfaMethod) => {
    try {
      const result = await authData.handleMFAChoice(mfaMethod);
      console.log("MFA choice result:", result);
      dispatch({
        type: AuthActions.MFA_CHOICE,
        payload: result,
        authData,
      });
      return result;
    } catch (error) {
      console.error("MFA choice error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  // Function to handle MFA confirmation
  const handleMFAChallenge = async (confirmationCode) => {
    try {
      // Use the centralized AuthState to handle MFA confirmation
      const result = await authData.handleMFAChallenge(confirmationCode);

      // Dispatch MFA_CONFIRMED action to update the local state
      dispatch({
        type: AuthActions.MFA_CONFIRMED,
        authData,
      });

      return result;
    } catch (error) {
      console.error("MFA confirmation error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  const handleConfirmUserAttribute = async (
    userAttributeKey,
    confirmationCode
  ) => {
    try {
      await authData.handleConfirmUserAttribute(
        userAttributeKey,
        confirmationCode
      );
      dispatch({ type: AuthActions.EMAIL_VERIFIED, authData });
    } catch (error) {
      if (error.name === "NetworkError" || error.name === "ServiceError") {
        dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      }
      throw error;
    }
  };

  const handleMFASetup = async (mfaMethod) => {
    try {
      // Use the centralized AuthState to handle MFA setup
      const updatedMfaSettings = await authData.handleMFASetup(mfaMethod);

      // Dispatch MFA_SETUP_COMPLETE action to update the local state
      dispatch({
        type: AuthActions.MFA_SETUP_COMPLETE,
        payload: updatedMfaSettings,
        authData,
      });

      return updatedMfaSettings;
    } catch (error) {
      console.error("MFA setup error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  const handleMFASetupSkipped = async () => {
    dispatch({ type: AuthActions.MFA_SKIPPED, authData });
  };

  const setupTOTP = async () => {
    try {
      const uri = await authData.setupTOTP();
      return uri;
    } catch (error) {
      console.error("TOTP setup error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  const verifyTOTPSetup = async (otpCode) => {
    try {
      const result = await authData.verifyTOTPSetup(otpCode);
      return result;
    } catch (error) {
      console.error("TOTP verification error:", error);
      dispatch({ type: AuthActions.SET_ERROR, payload: error, authData });
      throw error;
    }
  };

  const restartFlow = () => {
    authData.signOut();
    dispatch({ type: AuthActions.RESET });
  };

  // Initialize auth on component mount
  useEffect(() => {
    // Initialize the auth state if it hasn't been initialized yet
    if (!authData.state.isInitialized) {
      authData.initialize();
    }
  }, [authData]);

  // Listen for changes in authData state, particularly for signOut events
  useEffect(() => {
    console.log(
      "Setting up authData subscription, current state:",
      authData.state.isAuthenticated,
      state.signInFlowState
    );

    // Define the listener function that will be called when authData state changes
    const handleAuthStateChange = (newAuthState) => {
      console.log("Auth state changed:", newAuthState.isAuthenticated);

      // If the user was previously authenticated but is no longer,
      // this likely indicates a sign out has occurred
      if (
        !newAuthState.isAuthenticated &&
        state.signInFlowState !== SignInFlowStates.ACCOUNT_SETUP &&
        state.signInFlowState !== SignInFlowStates.INITIAL &&
        state.signInFlowState !== SignInFlowStates.MFA_CHOICE &&
        state.signInFlowState !== SignInFlowStates.MFA_CHALLENGE
      ) {
        console.log("Detected sign out in authData, resetting local state");
        dispatch({ type: AuthActions.RESET });
      }
    };

    // Subscribe to authData state changes
    const unsubscribe = authData.subscribe(handleAuthStateChange);

    // Clean up subscription when component unmounts
    return () => {
      console.log("Cleaning up authData subscription");
      unsubscribe();
    };
  }, [state.signInFlowState, dispatch]);

  // Create context value with state and functions
  const value = {
    state: {
      ...state,
      // Include necessary properties from authData for components that need them
      user: authData.state.user,
      userAttributes: authData.state.userAttributes,
      mfaSettings: authData.state.mfaSettings,
      isAuthenticated: authData.state.isAuthenticated,
      loading: authData.state.loading,
      error: authData.state.error || state.error,
      signInResponse: authData.state.signInResponse,
    },
    checkUser,
    handleSignIn,
    handleAccountSetup,
    handleMFAChallenge,
    handleMFAChoice,
    handleMFASetupSkipped,
    handleConfirmUserAttribute,
    restartFlow,
    handleMFASetup,
    setupTOTP,
    verifyTOTPSetup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
