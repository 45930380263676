import 'react-international-phone/style.css';

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';

export const MuiPhone = (props) => {
	const {
		inputValue,
		setInputValue,
		inputError,
		setInputError,
		pattern,
		isRequired,
		helpText,
		errorText,
		formError,
		TextFieldProps
	} = props;

  const [showError, setShowError] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const { inputValue: formattedValue, phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value: inputValue,
      countries: defaultCountries,
      onChange: (data) => {
        setInputValue(data.phone);

        if (pattern) {
          if ((formError && data.phone === '' && isRequired) || (data.phone !== '' && !pattern.test(data.phone))) {
            setInputError(true);
            setIsValid(false);
          } else {
            if (isRequired && data.phone !== '') {
              setIsValid(true);
            } else {
              setIsValid(false);
            }
            setInputError(false);
            setShowError(false);
          }
        } else {
          if (isRequired && data.phone !== '') {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }
      },
    });

  const handleOnFocus = () => {
    setIsFocused(true);
  }

  const handleOnBlur = () => {
    setShowError(inputError);
    setIsFocused(false);
  }

  return (
    <TextField
      {...TextFieldProps}
      variant="outlined"
      label="Phone number"
      placeholder="Phone number"
      value={formattedValue}
      onChange={handlePhoneValueChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      helperText={showError && inputError ? errorText : helpText}
      error={showError && inputError}
      color={isValid ? "success" : "primary"}
      focused={isValid || isFocused}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px', marginLeft: '-8px' }}
            tabIndex={-1}
          >
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: 'flex' }} />
              )}
              tabIndex={-1}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2} tabIndex={-1}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      sx={{
				'mb': 2,
				'& .MuiOutlinedInput-root': {
					'& input': {
					  '&:-webkit-autofill': {
						'WebkitBoxShadow': 'none !important',
						'boxShadow': 'none !important',
						'WebkitTextFillColor': 'white !important',
						'caretColor': 'inherit !important',
						'borderRadius': 'inherit',
						'backgroundColor': 'transparent !important',
						'transition': 'background-color 5000s ease-in-out 0s',
						},
					},
				},
				...TextFieldProps?.sx
			}}
    />
  );
};