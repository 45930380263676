import { useRouteError } from "react-router-dom";

export default function ErrorPage(props) {
	const error = useRouteError();
	console.log(error);

	return (
		<div>
			<div>Oops!</div>
			<div>An unexpected error has occurred.</div>
			<div><i>{error.statusText || error.message}</i></div>
		</div>
	)
}