import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Box, Divider, Paper, Typography, Button, RadioGroup, Radio, FormControlLabel, CircularProgress } from "@mui/material";
import { PhoneIphone, SettingsSuggestRounded, EmailRounded } from "@mui/icons-material";

export default function AdminMFAChoice() {
  const { 
    state,
    handleMFAChoice
  } = useContext(AuthContext);
  
  const { signInResponse } = state;
  const [selectedMfaMethod, setSelectedMfaMethod] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle MFA method selection
  const handleMfaMethodSelect = async () => {
    try {
      setIsSubmitting(true);
      setError('');
      
      console.log(`Selecting MFA method: ${selectedMfaMethod}`);
      await handleMFAChoice(selectedMfaMethod);
      
    } catch (error) {
      console.error("MFA method selection error:", error);
      setError(`Failed to select MFA method: ${error.message}`);
      setIsSubmitting(false);
    }
  };

  // Get available MFA methods from signInResponse
  const getMfaOptions = () => {
    if (!signInResponse?.nextStep?.allowedMFATypes) {
      return [];
    }
    
    const availableMethods = signInResponse.nextStep.allowedMFATypes;
    
    const options = [];
    
    if (availableMethods.includes('SMS')) {
      options.push({ 
        value: 'SMS', 
        label: 'Text Message (SMS)', 
        icon: <PhoneIphone /> 
      });
    }
    
    if (availableMethods.includes('TOTP')) {
      options.push({ 
        value: 'TOTP', 
        label: 'Authenticator App', 
        icon: <SettingsSuggestRounded /> 
      });
    }
    
    if (availableMethods.includes('EMAIL')) {
      options.push({ 
        value: 'EMAIL', 
        label: 'Email', 
        icon: <EmailRounded /> 
      });
    }
    
    return options;
  };

  const mfaOptions = getMfaOptions();

  // Set default selection if only one option is available
  useEffect(() => {
    if (mfaOptions.length === 1) {
      setSelectedMfaMethod(mfaOptions[0].value);
    }
  }, [mfaOptions]);

  return (
    <Box maxWidth={500} pt={5}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Choose Authentication Method
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        <Typography gutterBottom>
          Please select how you would like to receive your authentication code:
        </Typography>
        
        <Box my={2}>
          <RadioGroup 
            value={selectedMfaMethod} 
            onChange={(e) => setSelectedMfaMethod(e.target.value)}
          >
            {mfaOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {option.icon}
                    <Typography sx={{ marginLeft: 1 }}>
                      {option.label}
                    </Typography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </Box>
        
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        
        <Box pt={1} display="flex" justifyContent="center">
          <Button 
            variant="contained" 
            onClick={handleMfaMethodSelect}
            disabled={!selectedMfaMethod || isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Continue'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
