import React from "react";

import { confirmResetPassword, resetPassword, signIn } from "aws-amplify/auth";

import { Alert, Box, Button, Container, Divider, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import ValidatedTextField from "../misc/ValidatedTextField";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SignInFlowStates } from "../../constants/SignInFlowStates";
import { AuthActions } from "../../constants/AuthActions";
import { MuiOtpInput } from "mui-one-time-password-input";
import { MailOutline, Visibility, VisibilityOff, PersonOutline } from "@mui/icons-material";

export default function AdminForgotPasswordForm(props) {
	const { state, dispatch, checkUser } = React.useContext(AuthContext);
	const { user } = state;
	const [usernameInput, setUsernameInput] = React.useState("");
	const [usernameInputError, setUsernameInputError] = React.useState(false);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [response, setResponse] = React.useState(null);
	const [otp, setOtp] = React.useState("");
	const [newPasswordInput, setNewPasswordInput] = React.useState("");
	const [newPasswordInputError, setNewPasswordInputError] = React.useState(false);
	const [confirmNewPasswordInput, setConfirmNewPasswordInput] = React.useState("");
	const [showNewPasswordInput, setShowNewPasswordInput] = React.useState(false);
	const [error, setError] = React.useState(false);
	const navigate = useNavigate();

	const handleForgotPassword = async (e) => {
		e.preventDefault();
		try {
			setIsSubmitting(true);
			const forgotPasswordResponse = await resetPassword({ username: usernameInput });
			setResponse(forgotPasswordResponse);
			setError(false);
			setIsSubmitting(false);
		} catch (error) {
			setIsSubmitting(false);
			setError(error);
		}
	}

	const handleConfirmResetPassword = async () => {
		try {
			setIsSubmitting(true);
			await confirmResetPassword({
				username: usernameInput,
				confirmationCode: otp,
				newPassword: newPasswordInput
			});
			
			// Password reset successful - attempt sign in to determine next step
			try {
				const signInResult = await signIn({ 
					username: usernameInput, 
					password: newPasswordInput 
				});
				
				console.log("Sign in after password reset:", signInResult);
				dispatch({ type: AuthActions.SIGN_IN, payload: signInResult });
				
				if (signInResult.isSignedIn) {
					await checkUser();
					// Redirect to the LoginFlow with appropriate state based on verification status
					if (signInResult.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
						// The SIGN_IN action already sets the state to FORCE_NEW_PASSWORD
					} else if (
						signInResult.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
						signInResult.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
					) {
						// The SIGN_IN action already sets the state to MFA_CHALLENGE
					} else {
						// Go to login for now, LoginFlow will determine the correct state
						setResponse("reset");
					}
				} else {
					// Set response to "reset" to show the success message
					setResponse("reset");
				}
			} catch (signInError) {
				console.error("Error signing in after password reset:", signInError);
				// Still show success message but don't attempt navigation
				setResponse("reset");
			}
			
			setIsSubmitting(false);
		} catch (error) {
			setError(error);
			setIsSubmitting(false);
		}
	}

	const handleOtpChange = (newValue) => {
		setOtp(newValue.replace(/[^0-9]/g, ""));
	}

	const validateChar = (value) => {
		const isNumber = typeof value === "number";
		return (isNumber || value !== "") && !isNaN(Number(value));
	}

	if (user && user !== "checking") {
		return <Navigate to="/" replace />
	} else if (response === null) {
		return (
			<Container sx={{ display: "flex", justifyContent: "center" }}>
				<Box maxWidth={400} pt={5}>
					<Paper>
						<Typography variant="h4" px={2} py={1}>Forgot Password</Typography>
						<Divider />
						<Box p={2}>
							{error !== false && <Alert severity="error" variant="outlined" sx={{mb: 2}}>
								{error.message.includes("Attempt limit exceeded") ?
									"Too many attempts. Please try again later." :
									error.message.includes("combination not found") ?
										"The username you entered could not be found. Please double-check the information you entered and try again." :
										error.message.includes("there is no registered/verified email") || error.message.includes("there is no registered/verified phone") ?
											"An account with the username you entered exists, but the contact method is not verified. A recovery code cannot be sent to an unverified contact method.":
											"There was an issue sending a recovery code. Please double-check the information you entered and try again."
								}
							</Alert>}
							<form onSubmit={handleForgotPassword}>
								<ValidatedTextField
									inputValue={usernameInput}
									setInputValue={setUsernameInput}
									inputError={usernameInputError}
									setInputError={setUsernameInputError}
									isRequired={true}
									errorText="Please enter your username."
									formError={error !== false}
									TextFieldProps={{
										disabled: isSubmitting,
										size: "small",
										type: "text",
										label: "Username",
										margin: "normal",
										fullWidth: true,
										sx: { mt: 0 }
									}}
								/>
								<Button type="submit" disabled={isSubmitting || usernameInputError || usernameInput === ""} variant="contained">Send Recovery Code</Button>
							</form>
						</Box>
					</Paper>
				</Box>
			</Container>
		);
	} else if (response?.nextStep?.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
		return (
			<Container sx={{ display: "flex", justifyContent: "center" }}>
				<Box maxWidth={400} pt={5}>
					<Paper>
						<Typography variant="h4" px={2} py={1}>Forgot Password</Typography>
						<Divider />
						<Box p={2} textAlign="center">
							{error && <Alert severity="error" variant="outlined" sx={{mb: 2, textAlign: "initial"}}>
								{error.message.includes("request a code again") ?
									"The code you entered is invalid or expired." :
									// error.message.includes("Attempt limit exceeded") ?
									// 	"Too many attempts. Please try again later." :
										"There was an issue resetting your password. Please double-check the information you entered and try again."
								}
							</Alert>}
							<PersonOutline sx={{ height: "72px", width: "72px" }} />
							<Box p={1}>
								<Typography gutterBottom>A recovery code has been sent to your registered contact method.</Typography>
								<Typography gutterBottom>Enter it below to reset your password.</Typography>
							</Box>
								<MuiOtpInput
									autoFocus
									length={6}
									TextFieldsProps={{ placeholder: '-', error: error, size: "small" }}
									value={otp}
									onChange={handleOtpChange}
									validateChar={validateChar}
								/>
							<ValidatedTextField
								inputValue={newPasswordInput}
								setInputValue={(newValue) => {
									setNewPasswordInput(newValue);
									setConfirmNewPasswordInput(() => {return confirmNewPasswordInput})
								}}
								inputError={newPasswordInputError}
								setInputError={setNewPasswordInputError}
								pattern={/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
								isRequired={true}
								helpText="Password must be at least 8 characters and contain at least one number, one special character, one lowercase letter, and one uppercase letter."
								errorText="Password must be at least 8 characters and contain at least one number, one special character, one lowercase letter, and one uppercase letter."
								formError={error !== false}
								TextFieldProps={{
									disabled: isSubmitting,
									size: "small",
									type: showNewPasswordInput ? "text" : "password",
									label: "New Password",
									margin: "normal",
									fullWidth: true,
									InputProps: {
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													size="small"
													onClick={() => setShowNewPasswordInput(!showNewPasswordInput)}
												>
													{showNewPasswordInput ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}
								}}
							/>
							<TextField
								value={confirmNewPasswordInput}
								onChange={(e) => {setConfirmNewPasswordInput(e.target.value)}}
								error={newPasswordInput !== "" && newPasswordInput !== confirmNewPasswordInput}
								color={newPasswordInput !== "" && newPasswordInput === confirmNewPasswordInput ? "success" : "primary"}
								focused={newPasswordInput !== "" && newPasswordInput === confirmNewPasswordInput}
								helperText={newPasswordInput !== "" && newPasswordInput !== confirmNewPasswordInput ? "Passwords must match." : false}
								disabled={isSubmitting}
								size="small"
								type={showNewPasswordInput ? "text" : "password"}
								label="Confirm New Password"
								margin="normal"
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												size="small"
												onClick={() => setShowNewPasswordInput(!showNewPasswordInput)}
											>
												{showNewPasswordInput ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}}
								sx={{mt: 1, mb: 2}}
							/>
							<Button
								onClick={handleConfirmResetPassword}
								variant="contained"
								disabled={
									isSubmitting ||
									newPasswordInputError ||
									newPasswordInput !== confirmNewPasswordInput ||
									newPasswordInput === "" ||
									otp === "" ||
									confirmNewPasswordInput === ""
								}
								fullWidth
							>
								Reset Password
							</Button>
						</Box>
					</Paper>
				</Box>
			</Container>
		);
	} else if (response === "reset") {
		return (
			<Container sx={{ display: "flex", justifyContent: "center" }}>
				<Box maxWidth={400} pt={5}>
					<Paper>
						<Typography variant="h4" px={2} py={1}>Forgot Password</Typography>
						<Divider />
						<Box p={2}>
							<Typography gutterBottom>Password reset successful.</Typography>
							<Button 
								onClick={() => {
									// Navigate back to LoginFlow - the initialization will determine the correct flow state
									navigate("/login");
								}} 
								variant="contained"
							>
								Continue
							</Button>
						</Box>
					</Paper>
				</Box>
			</Container>
		);
	}
}