import React from 'react';
import { Box, CircularProgress, Typography, Paper, useTheme } from '@mui/material';

const Loading = ({ message = "Loading..." }) => {
  const theme = useTheme();

  return (
    <Box 
      display="flex" 
      justifyContent="center"
      minHeight="100vh"
      bgcolor={theme.palette.background.default}
      pt={5}
    >
      <Box maxWidth={500} width="100%">
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary
          }}
        >
          <CircularProgress 
            size={60} 
            thickness={4} 
            color="primary"
          />
          <Typography 
            variant="h6" 
            sx={{ 
              marginTop: 2,
              color: theme.palette.text.primary
            }}
          >
            {message}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Loading;