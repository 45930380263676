import React, { useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, Divider, Paper, Typography, CircularProgress, Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { PhoneIphone, SettingsSuggestRounded, EmailRounded } from "@mui/icons-material";

export default function AdminMFAForm(props) {
  const { 
    state,
    handleMFAChallenge,
    restartFlow,
  } = React.useContext(AuthContext);
  
  // Access signInResponse from state
  const { signInResponse } = state;
  const navigate = useNavigate();
  
  // Add a useEffect to validate we have the required signInResponse for MFA challenge
  React.useEffect(() => {
    console.log("AdminMFAForm mounted with signInResponse:", signInResponse);
    
    // Validate we have the MFA challenge data
    if (!signInResponse || 
        (signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_SMS_CODE" && 
         signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_TOTP_CODE" &&
         signInResponse.nextStep?.signInStep !== "CONFIRM_SIGN_IN_WITH_EMAIL_CODE")) {
      console.error("Missing or invalid signInResponse for MFA challenge:", signInResponse);
    }
  }, [signInResponse]);

  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);

  const handleOtpChange = (newValue) => {
    setOtp(newValue.replace(/[^0-9]/g, ""));
  };

  const handleSignInConfirmation = async (otpCode) => {
    try {
      // Set verifying state to show loading indicator and disable input
      setIsVerifying(true);
      setError(false);
      
      console.log("Attempting to confirm MFA with code:", otpCode);
      await handleMFAChallenge(otpCode);
      
      // MFA confirmed successfully, now update authentication state
      console.log("MFA confirmed successfully");
      
    } catch (error) {
      console.error("MFA confirmation error:", error);
      setError(true);
      setOtp(""); // Reset OTP input for retry
      setIsVerifying(false); // Reset verifying state to enable retry
    }
  };

  const validateChar = (value) => {
    const isNumber = typeof value === "number";
    return (isNumber || value !== "") && !isNaN(Number(value));
  };

  const renderMFAContent = () => {
    console.log(`SignInResponse in renderMFAContent: ${JSON.stringify(signInResponse)}`);
    switch (signInResponse?.nextStep?.signInStep) {
      case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
        return (
          <>
            <PhoneIphone sx={{ height: "72px", width: "72px" }} />
            <Box p={1}>
              <Typography gutterBottom>
                An authentication code has been sent to
                <br />
                <b>
                  <i>{signInResponse?.nextStep?.codeDeliveryDetails.destination}</i>
                </b>
                .
              </Typography>
              <Typography gutterBottom>Enter it below to sign in.</Typography>
            </Box>
          </>
        );
      case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        return (
          <>
            <SettingsSuggestRounded sx={{ height: "72px", width: "72px" }} />
            <Box p={1}>
              <Typography gutterBottom>
                Enter the code from your authenticator app to sign in.
              </Typography>
            </Box>
          </>
        );
      case "CONFIRM_SIGN_IN_WITH_EMAIL_CODE":
        return (
          <>
            <EmailRounded sx={{ height: "72px", width: "72px" }} />
            <Box p={1}>
              <Typography gutterBottom>
                An authentication code has been sent to
                <br />
                <b>
                  <i>{signInResponse?.nextStep?.codeDeliveryDetails.destination}</i>
                </b>
              </Typography>
              <Typography gutterBottom>Enter it below to sign in.</Typography>
            </Box>
          </>
        );
      default:
        // Stay in MFA_CHALLENGE state with error message instead of redirecting
        return (
          <Box p={2}>
            <Typography color="error" gutterBottom>
              Invalid MFA challenge. Please try signing in again.
            </Typography>
            <Button 
              variant="contained" 
              onClick={() => restartFlow()}
            >
              Return to Sign In
            </Button>
          </Box>
        );
    }
  };

  // No need to check for valid MFA challenge or redirect - this is handled by the LoginFlow component

  return (
    <Box maxWidth={400} pt={5}>
      <Paper>
        <Typography variant="h4" px={2} py={1}>
          Sign In
        </Typography>
        <Divider />
        <Box p={2} textAlign="center">
          {renderMFAContent()}
          <MuiOtpInput
            autoFocus
            length={6}
            TextFieldsProps={{ 
              placeholder: "-", 
              error: error, 
              size: "small",
              disabled: isVerifying, // Disable input while verifying
            }}
            value={otp}
            onChange={handleOtpChange}
            validateChar={validateChar}
            onComplete={handleSignInConfirmation}
            disabled={isVerifying} // Disable the entire component while verifying
          />
          
          {/* Show loading indicator while verifying */}
          {isVerifying && (
            <Box mt={2} display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          )}
          
          {/* Show error message if validation fails */}
          {error && (
            <Typography color="error" mt={1}>
              Invalid authentication code. Please double-check and try again.
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}